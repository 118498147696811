import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hautausmaa from "../images/hautausmaa.jpg"
const HautausmaaAlanNimikkeistö = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Hautausmaa-alan nimikkeistö" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Hautausmaa-alan nimikkeistö</h1>
        </div>
      </div>
    </div>
    <div className="spacer-60" />
    <div className="row">
      <div className="col-md-8 col-md-offset-2">
        <img
          className="pull-right padding-l25 w-6/12"
          src={hautausmaa}
          alt=""
        />
        <p>
          Hautausmaa-alan nimikkeistö on laaja. Eri puolella Suomea voi samalla
          nimityksellä olla monta eri merkitystä. Toisaalta taas yhdellä
          käsitteellä, asialla, alueella, tms. voi olla useita nimityksiä ja
          rinnakkaisia ilmaisutapoja.
        </p>

        <p>
          Tämän oppaan nimikkeistö perustuu suurelta osin lakiteksteihin. Näin
          on saatu useille samaa tarkoittaville ilmauksille niiden virallinen
          nimitys. Tarkoituksena on, että kulloinkin käytetyllä termillä on
          yksiselitteinen tarkoite.
        </p>

        <p>
          Parhaimmillaan tämä teos palvelee oppimateriaalina hautausmaa-alan
          ammattilaisia, puutarha-alan oppilaitoksia, rakennuttajia,
          suunnittelijoita ja urakoitsijoita. Tämä nimikkeistö on laadittu
          yhteistyössä Suomen Hautaustoiminnan Keskusliiton, Kirkkohallituksen
          ja Seurakuntapuutarhurit ry:n kanssa.
        </p>

        <p>
          Nimikkeistöä tullaan päivittämään, mikäli siihen on tarvetta.
          Nimikkeistössä on otettu huomioon 1.1.2004 voimaan astuva
          hautaustoimilaki.
        </p>

        <p>
          Lataa evankelisluterilaisen kirkon seurakuntien hautausmaa-alan
          nimikkeistö alta.
        </p>

        <a
          className="button button-primary"
          href="doc/hautausmaa_alan_nimikkeet.pdf"
        >
          Lataa
        </a>
      </div>

      <div className="spacer-75" />
    </div>
  </Layout>
)

export default HautausmaaAlanNimikkeistö
